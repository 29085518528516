import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Image from "../components/Image"
import { FaGrimace } from "react-icons/fa"

import * as Color from "../utils/color"
import { mobile, tablet } from "../utils/breakpoint"

const List = styled.div`
	${tw`mt-4`}

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 16px;

	${Image} {
		object-fit: cover;
		width: 100%;
		height: 200px;

		border-radius: 8px;
	}
`

const Sub = styled.div`
	${tw`text-xl`}
	font-weight: bold;
`

const More = styled.div`
	${tw`mt-8`}
`

const Content = styled.p`
	${tw`text-base md:text-xl mt-8`}
`

const Images = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 16px;

	${Image} {
		object-fit: cover;
		width: 100%;
	}
`

const Block = styled.div`
	${tw`mt-8`}
`

const Blocks = styled.div`
	${Block}:nth-of-type(1) {
		${Content} {
			&:first-letter {
				${tw`text-6xl`}
				font-weight: bold;
				display: block;
				float: left;
				font-weight: bold;
				line-height: 90%;
				margin-right: 6px;
				margin-bottom: -2px;
			}
		}
	}

	${Block}:not(:first-of-type) {
		${tw`mt-8`}
	}
`

const Category = styled.h6`
	${tw`text-sm md:text-base`}
	text-transform: uppercase;
	font-weight: bold;
	color: ${Color.secondary};
`

const Author = styled.h6`
	${tw`text-sm md:text-base`}
	font-weight: bold;
	color: ${Color.primary_600};

	> span {
		color: ${Color.primary};
	}
`

const PostedAt = styled.h6`
	${tw`text-sm md:text-base`}
	font-weight: bold;
	color: ${Color.primary_600};

	> span {
		color: ${Color.primary};
	}
`

const Short = styled.span`
	color: lightgray;
	font-weight: bold;
`

const Title = styled.h1`
	${tw`text-4xl md:text-5xl leading-none`}
	font-weight: bold;

	color: ${Color.primary};
`

const NoThumbnail = styled.div`
	width: 100%;
	height: 50vh;
	min-height: 400px;
	border-top: solid 1px lightgray;
	border-bottom: solid 1px lightgray;

	display: flex;
	justify-content: center;
	align-items: center;
`

const Thumbnail = styled.img`
	border: solid 1px lightgray;
	width: 100%;
	height: 50vh;
	min-height: 400px;
	object-fit: cover;
`

const Container = styled.div`
	padding: 2em 0;
`

const Post = styled.article`
	display: grid;
	grid-auto-columns: 0.8fr;
	grid-template-areas:
		". category . . ."
		". title title title ."
		". date . . ."
		". author . . ."
		"thumbnail thumbnail thumbnail thumbnail thumbnail"
		". blocks blocks blocks ."
		". more more more .";

	@media ${mobile} {
		grid-template-areas:
			". category . . . . ."
			". title title title title title . "
			". date date date . . ."
			". author author author . . ."
			"thumbnail thumbnail thumbnail thumbnail thumbnail thumbnail thumbnail"
			". blocks blocks blocks blocks blocks ."
			". more more more more more .";
	}

	@media ${tablet} {
		grid-template-areas:
			". category . . . . ."
			". title title title title title . "
			". date date date . . ."
			". author author author . . ."
			"thumbnail thumbnail thumbnail thumbnail thumbnail thumbnail thumbnail"
			". blocks blocks blocks blocks blocks ."
			". more more more more more .";
	}

	${Category} {
		${tw`mb-1`}
		grid-area: category;
	}

	${Title} {
		${tw`mb-4`}
		grid-area: title;
	}

	${PostedAt} {
		${tw`mb-1`}
		grid-area: date;
	}

	${Author} {
		${tw`mb-6`}
		grid-area: author;
	}

	${Thumbnail} {
		${tw`mb-6`}
		grid-area: thumbnail;
	}

	${NoThumbnail} {
		${tw`mb-6`}
		grid-area: thumbnail;
	}

	${Blocks} {
		grid-area: blocks;
	}

	${More} {
		grid-area: more;
	}
`

const PostLayout = ({ data }) => {
	const { title, shortDescription, author, blocks, postCategory, createdAt, thumbnail, images } = data.strapiPosts
	const formatDate = createdAt.split("T")[0].replace(/-/g, "/")

	return (
		<>
			<Container>
				<Post>
					<Category>{postCategory ? postCategory.name : "untitled"}</Category>
					<Title>
						{title}
						<Short>{" " + shortDescription.toLowerCase()}</Short>
					</Title>
					<PostedAt>
						<span>Posted at </span>
						{formatDate}
					</PostedAt>
					<Author>
						<span>Written by </span>
						{author}
					</Author>
					{thumbnail ? (
						<Thumbnail src={thumbnail.publicURL} />
					) : (
						<NoThumbnail>
							<FaGrimace size={48} />
						</NoThumbnail>
					)}

					<Blocks>
						{blocks.map((item, index) => {
							return (
								<Block key={index}>
									{"pictures" in item && item.pictures != null && (
										<Images>
											{item.pictures.map((imgItem, index) => (
												<Image key={index} imageUrl={`${process.env.API_URL}${imgItem.url}`} />
											))}
										</Images>
									)}

									{"youtubeURL" in item && item.youtubeURL != null && (
										<iframe
											width="100%"
											height="400"
											src={item.youtubeURL}
											frameBorder="0"
											allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
										></iframe>
									)}
									<Content>{item.content}</Content>
								</Block>
							)
						})}
					</Blocks>

					{images.length > 0 && (
						<More>
							<Sub>More Images</Sub>
							<List>
								{images.map((item, index) => {
									return <Image key={index} imageUrl={`${process.env.API_URL}${item.url}`} />
								})}
							</List>
						</More>
					)}
				</Post>
			</Container>
		</>
	)
}

export const query = graphql`
	query($id: String!) {
		strapiPosts(id: { eq: $id }) {
			title
			shortDescription
			createdAt
			author
			images {
				url
			}
			postCategory {
				name
			}
			thumbnail {
				publicURL
			}
			blocks {
				content
				pictures {
					url
				}
				youtubeURL
			}
		}
	}
`

export default PostLayout
